/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import axios from 'axios';
// import store from '@/store'
// import qs from 'qs';
// import store from '@/store';
// import { mapMutations } from 'vuex';

// console.log('axioz: Company: ' + store.state.company);
// axios.defaults.baseURL = process.env.M3_BASE_API
// axios.defaults.headers.common.Accept = 'application/json'
// axios.defaults.headers.common['Content-Type'] = 'application/json'

// const apiClient = axios.create({
//   baseURL: process.env.M3_BASE_API,
//   withCredentials: false,
//   headers: {
//     Accept: 'application/json',
//     ContentType: 'application/json',
//     Company: '',
//   },
// });

// const companyApi = '/api/companies/';
// const optionApi = '/api/option/';
// const warningApi = '/api/warning/';
const userRights = '/api/user/rights';
const changeUserPassword = '/api/user/ChangeUserPassword';
const changePassword = '/api/user/ChangePassword';
const appealListApi = '/api/warning/getAppealList/';
// const appealDetailApi = '/api/warning/getAppeal/';
const appealApprovalApi = '/api/warning/appealApprove/';
const appealApi = '/api/warning/appeal/';
const driverApi = '/api/drivers/';
const vehicleApi = '/api/vehicles/';
// const liveApi = '/api/live/location';
const tripDetailApi = '/api/trip/detail/';
const tripApi = '/api/trip/webTrip';
// const tripTtargetListApi = '/api/trip/targetList';
// const companyGroupApi = 'api/companyGroups/';
const vehicleGroupApi = 'api/vehiclegroups';
const driverGroupApi = 'api/drivergroups';
const profileIdlingApi = '/api/profile/idling';
const profileOverviewApi = '/api/profile/overview';
const profileWarningCountApi = '/api/profile/warningcount';
const profileWarningCountper100kmApi = '/api/profile/warningCountAvg';
const profileScore = '/api/profile/score';
const profileRank = '/api/profile/rank';
const profileWarningGroup = '/api/profile/warninggroup';
const profileWarningList = '/api/profile/warning';
const warningDataOptionListApi = '/api/warningData/GetOptionList';
const WarningData = '/api/warningData/warningrowdata';
const WarningVideo = '/api/warningVideos';
const tripOptionListApi = '/api/tripMaintenance/GetOptionList';
const tripListApi = '/api/tripMaintenance/QueryVehicleTrip';
const driverUpdateApi = '/api/tripMaintenance/UpdateTripDriver';
const uploadFileApi = '/api/tripMaintenance/UploadTripDriver';
const heatMapApi = 'api/warning/WarningHeatMap';
// const videoFileApi = '/api/video/';
const waylistApi = '/api/heatmap';
const waydetailApi = '/api/heatmap/detail';

const pmslistApi = '/api/pms/GetList';
export default {
  async getPmsWarnings(axios, form) {
    const result = await axios.$post(pmslistApi, form);
    return result;
  },
  async getUserRights(axios) {
    const result = await axios.$post(userRights);
    return result;
  },

  // change other users password
  async changeUserPassword(axios, data) {
    const result = await axios.$post(changeUserPassword, data);
    return result;
  },

  async changePassword(axios, data) {
    const result = await axios.$post(changePassword, data);
    return result;
  },

  async getProfileOverview(axios, query) {
    const result = await axios.$get(profileOverviewApi, { params: query });
    return result;
  },

  async getSafetyScore(axios, query) {
    const result = await axios.$get(profileScore, { params: query });
    return result;
  },

  async getWarningGroup(axios, query) {
    const result = await axios.$get(profileWarningGroup, { params: query });
    return result;
  },

  async getWarningCountper100(axios, query) {
    const result = await axios.$get(profileWarningCountper100kmApi, { params: query });
    return result;
  },

  async getAppealList(axios, query) {
    const result = await axios.$get(appealListApi, {params:query});
    return result;
  },

  async getWarningData(axios, form) {
    const result = await axios.$post(WarningData, form, { responseType: 'blob' });
    return result;
  },

  async getWarningVideo(axios, form) {
    const result = await axios.$post(WarningVideo, form, { responseType: 'blob' });
    return result;
  },

  async getWarningDataOptions(axios) {
    const result = await axios.$get(warningDataOptionListApi);
    return result;
  },

  async getWarningDataOptionList(axios, url, query) {
    const result = await axios.$get(url, {params: query});
    return result;
  },

  async getTripOptions(axios) {
    const result = await axios.$get(tripOptionListApi);
    return result;
  },
  async getTripList(axios, form) {
    const result = await axios.$post(tripListApi, form);
    return result;
  },
  async postDriverUpdate(axios, data) {
    const result = await axios.$post(driverUpdateApi, data);
    return result;
  },
  async postUploadFile(axios, data) {
    const result = await axios.$post(uploadFileApi, data);
    return result;
  },
  // async getAppealDetail(api, id) {
  //   const result = await api.get(appealDetailApi + id);
  //   return result;
  // },
  async postAppealApproval(axios, id, data) {
    const result = await axios.$post(appealApprovalApi + id, data);
    return result;
  },

  async getVideoFile(api, mediaFullId) {
    const result = await api.get(appealListApi);
    return result;
  },

  async getHeatMap(axios, query) {
    const result = await axios.$post(heatMapApi, query);
    return result;
  },
  async getWayList(axios, query) {
    return await axios.$post(waylistApi, query);
  },
  async getWayDetail(axios, query) {
    return await axios.$post(waydetailApi,query);
  },
  // getLive() {
  //   return apiClient.get(liveApi);
  // },
  // computed: {
  //   company: {
  //     get() {
  //       console.log(this.$store.state.app.company)
  //       return this.$store.state.app.company;
  //     },
  //     set(val) {
  //       console.log(val)
  //       apiClient.defaults.headers.Company = val;
  //     }
  //   }
  // },
  // watch: {
  //   "this.$store.state.app.company"(val) {
  //     console.log('1')
  //     apiClient.defaults.headers.Company = val
  //   }
  // },
  // setCompany(company) {
  //   apiClient.defaults.headers.Company = company
  // },
  // setCompany(company) {
  //   apiClient.defaults.headers.Company = 'clp';
  // },
  setToken(token) {
    console.log('axios token');
    console.log(token);
    // apiClient.defaults.headers.Authorization = 'Bearer ' + token;
  },
  // Function: getCompanyList
  // Description: Retrieve company list with filter and paging.
  // Usage: Call ApiService.function().then() in ProfileTable.vue create() to retrieve response.
  // getCompanyList(query) {
  //   console.log('In getCompanyList');

  //   // Query check
  //   if (query === undefined || query === null) {
  //     return apiClient.get(companyApi, {
  //       params: {},
  //     });
  //   }

  //   // Query result from M3 Database
  //   return apiClient.get(companyApi, {
  //     params: {
  //       CompanyId: query.CompanyId,
  //       Code: query.Code,
  //       Name: query.Name,
  //       SortBy: query.SortBy,
  //       IsSortAscending: query.IsSortAscending,
  //       Page: query.Page,
  //       PageSize: query.PageSize,
  //     },
  //   });
  // },
  // getCompanies(query) {
  //   // page, pageSize, sortBy, sortDesc
  //   if (query.sortBy == null || query.sortBy === undefined) {
  //     query.sortBy = '';
  //   }
  //   if (query.sortDesc == null || query.sortDesc === undefined) {
  //     query.sortDesc = false;
  //   }

  //   return apiClient.get(companyApi, {
  //     params: {
  //       page: query.page,
  //       pageSize: query.pageSize,
  //       sortBy: query.sortBy,
  //       IsSortAscending: !query.sortDesc,
  //     },
  //   });
  // },
  // // Company
  // getCompany(id) {
  //   return apiClient.get(companyApi + id);
  // },

  //   return apiClient.post(appealApprovalApi + id, data);
  // },
  // postCompany(company) {
  //   return apiClient.post(companyApi, company);
  // },
  // putCompany(company) {
  //   return apiClient.put(companyApi + company.id, company);
  // },
  // deleteCompany(id) {
  //   return apiClient.delete(companyApi + id);
  // },
  // // Options
  // getTimeZones() {
  //   return apiClient.get(optionApi + 'timezones');
  // },
  // getTripTtargetList(value) {
  //   return apiClient.get(
  //     tripTtargetListApi + '?CompanyId=6&TargetType=' + value
  //   );
  // },
  // getWarningTypes() {
  //   return apiClient.get(optionApi + 'warningtypes');
  // },
  // getCompanyOption() {
  //   return apiClient.get(optionApi + 'companies');
  // },
  // getVehicleOption(companyId) {
  //   return apiClient.get(optionApi + 'vehicles', {
  //     params: {
  //       companyId,
  //     },
  //   });
  // },
  // getDriverOption(companyId) {
  //   return apiClient.get(optionApi + 'drivers', {
  //     params: {
  //       companyId,
  //     },
  //   });
  // },
  // // Warnings
  // getWarnings(query) {
  //   console.log(query);
  //   // page, pageSize, sortBy, sortDesc
  //   if (query.sortBy === null || query.sortBy === undefined) {
  //     query.sortBy = '';
  //   }
  //   if (query.sortDesc === null || query.sortDesc === undefined) {
  //     query.sortDesc = false;
  //   }

  //   return apiClient.get(warningApi, {
  //     params: {
  //       companyId: query.companyId,
  //       vehicleId: query.vehicleId,
  //       warningTypeText: query.warningTypeText,
  //       startTime: query.startTime,
  //       endTime: query.endTime,
  //       page: query.page,
  //       pageSize: query.pageSize,
  //       sortBy: query.sortBy,
  //       IsSortAscending: !query.sortDesc,
  //     },
  //     paramsSerializer(params) {
  //       return qs.stringify(params);
  //     },
  //   });
  // },
  // Apply Appeal
  async applyAppeal(axios, id, data) {
    const result = await axios.$post(appealApi + id, data);
    return result;
  },
  // postAppeal(id, data) {
  //   return apiClient.post(appealApi + id, data);
  // },
  //
  // // Appeal Detail
  // getAppealDetail(id) {
  //   return apiClient.get(appealDetailApi + id);
  // },
  // // Drivers
  async getDriverList(axios, query) {
    const result = await axios.$get(driverApi, { params: query });
    return result;
  },
  // getDriverList(query) {
  //   // page, pageSize, sortBy, sortDesc
  //   if (query.sortBy === null || query.sortBy === undefined) {
  //     query.sortBy = '';
  //   }
  //   if (query.sortDesc === null || query.sortDesc === undefined) {
  //     query.sortDesc = false;
  //   }

  //   return apiClient.get(driverApi, {
  //     params: {
  //       companyId: query.CompanyId,
  //       companyGroupId: query.companyGroupId,
  //       driverId: query.driverId,
  //       driverGroupId: query.driverGroupId,
  //       name: query.name,
  //       code: query.code,
  //       staffCardNo: query.staffCardNo,
  //       sortBy: query.sortBy,
  //       IsSortAscending: !query.sortDesc,
  //       page: query.page,
  //       pageSize: query.pageSize,
  //     },
  //     paramsSerializer(params) {
  //       return qs.stringify(params);
  //     },
  //   });
  // },
  async getDriver(axios, id) {
    const result = await axios.$get(driverApi + '/' + id);
    return result;
  },
  // getDriver(id) {
  //   return apiClient.get(driverApi + id);
  // },
  // postDriver(driver) {
  //   return apiClient.post(driverApi, driver);
  // },
  // putDriver(driver) {
  //   return apiClient.put(driverApi + driver.id, driver);
  // },
  // deleteDriver(id) {
  //   return apiClient.delete(driverApi + id);
  // },
  // // Driver Group
  async getDriverGroupList(axios, query) {
    const result = await axios.$get(driverGroupApi, { params: query });
    return result;
  },
  // getDriverGroupList(query) {
  //   return apiClient.get(driverGroupApi, {
  //     params: {
  //       companyId: query.CompanyId,
  //       companyGroupId: query.companyGroupId,
  //       driverId: query.driverId,
  //       driverGroupId: query.driverGroupId,
  //       name: query.name,
  //       code: query.code,
  //       staffCardNo: query.staffCardNo,
  //       sortBy: query.sortBy,
  //       IsSortAscending: query.IsSortAscending,
  //       page: query.page,
  //       pageSize: query.pageSize,
  //     },
  //   });
  // },
  async getDriverGroup(api, id) {
    const result = await api.get(driverGroupApi + '/' + id);
    return result;
  },
  // getDriverGroup(id) {
  //   return apiClient.get(driverGroupApi + id);
  // },
  // // TODO: CREATE DRIVER GROUP,
  // //       UPDATE DRIVER GROUP, DELETE DRIVER GROUP,
  // //       ADD DRIVERS TO DRIVER GROUP

  // // Vehicles
  async getVehicleList(axios, query) {
    const result = await axios.$get(vehicleApi, { params: query });
    return result;
  },
  // getVehicleList(query) {
  //   // page, pageSize, sortBy, sortDesc
  //   if (query.sortBy === null || query.sortBy === undefined) {
  //     query.sortBy = '';
  //   }
  //   if (query.sortDesc === null || query.sortDesc === undefined) {
  //     query.sortDesc = false;
  //   }

  //   return apiClient.get(vehicleApi, {
  //     params: {
  //       companyId: query.CompanyId,
  //       companyGroupId: query.companyGroupId,
  //       vehicleId: query.vehicleId,
  //       vehicleGroupId: query.vehicleGroupId,
  //       plate: query.plate,
  //       page: query.page,
  //       pageSize: query.pageSize,
  //       sortBy: query.sortBy,
  //       IsSortAscending: !query.sortDesc,
  //     },
  //     paramsSerializer(params) {
  //       return qs.stringify(params);
  //     },
  //   });
  // },
  async getVehicle(axios, id) {
    const result = await axios.$get(vehicleApi + '/' + id);
    return result;
  },
  // getVehicle(id) {
  //   return apiClient.get(vehicleApi + id);
  // },
  // postVehicle(vehicle) {
  //   return apiClient.post(vehicleApi, vehicle);
  // },
  // putVehicle(vehicle) {
  //   return apiClient.put(vehicleApi + vehicle.id, vehicle);
  // },
  // deleteVehicle(id) {
  //   return apiClient.delete(vehicleApi + id);
  // },
  // // Vehicle Group
  async getVehicleGroupList(axios, query) {
    const result = await axios.$get(vehicleGroupApi, { params: query });
    return result;
  },

  async getTrips(axios, query) {
    const result = await axios.$post(tripApi, query);
    return result;
  },
  // getVehicleGroupList(query) {
  //   return apiClient.get(vehicleGroupApi, {
  //     params: {
  //       companyId: query.CompanyId,
  //       companyGroupId: query.companyGroupId,
  //       vehicleGroupId: query.vehicleGroupId,
  //       plate: query.plate,
  //       sortBy: query.sortBy,
  //       IsSortAscending: query.IsSortAscending,
  //       page: query.page,
  //       pageSize: query.pageSize,
  //     },
  //   });
  // },

  async getVehicleGroup(axios, id) {
    const result = await axios.$get(vehicleGroupApi + '/' + id);
    return result;
  },

  async getTripDetails(axios, query) {
    const result = await axios.$get(tripDetailApi, { params: query });
    return result;
  },
  // getVehicleGroup(id) {
  //   return apiClient.get(vehicleGroupApi + id);
  // },
  // // TODO: CREATE VEHICLE GROUP,
  // //       UPDATE VEHICLE GROUP, DELETE VEHICLE GROUP
  // //       ADD VEHICLES TO VEHICLE GROUP

  // // TripDetail
  // getTripDetails(query) {
  //   // page, pageSize, sortBy, sortDesc
  //   if (query.sortBy === null || query.sortBy === undefined) {
  //     query.sortBy = '';
  //   }
  //   if (query.sortDesc === null || query.sortDesc === undefined) {
  //     query.sortDesc = false;
  //   }
  //   return apiClient.get(tripDetailApi, {
  //     params: {
  //       TripId: query.id,
  //     },
  //     paramsSerializer(params) {
  //       return qs.stringify(params);
  //     },
  //   });
  // },
  // // Trips
  // getTrips(query) {
  //   // page, pageSize, sortBy, sortDesc
  //   if (query.sortBy === null || query.sortBy === undefined) {
  //     query.sortBy = '';
  //   }
  //   if (query.sortDesc === null || query.sortDesc === undefined) {
  //     query.sortDesc = false;
  //   }
  //   return apiClient.get(tripApi, {
  //     params: {
  //       vehicleIds: 695,
  //       startTime: query.startTime,
  //       endTime: query.endTime,
  //     },
  //     paramsSerializer(params) {
  //       return qs.stringify(params);
  //     },
  //   });
  // },
  // TLive



  // getLive({ $axios }) {
  //   alert('1212');
  //   return $axios.get(liveApi).then(response => {
  //     return response;
  //   });
  // try {
  //   alert(liveApi);
  //   const result = $axios.$get(liveApi);
  //   return result;
  // } catch (err) {
  //   console.log(err);
  // }
  // },
  // getLive(query) {
  //   return this.$axios.get(liveApi, {
  //     params: {
  //       companyId: query.CompanyId,
  //     },
  //     paramsSerializer(params) {
  //       return qs.stringify(params);
  //     },
  //   });
  // },
  // // companyGroup
  // getcompanyGroups(query) {
  //   // page, pageSize, sortBy, sortDesc
  //   if (query.sortBy === null || query.sortBy === undefined) {
  //     query.sortBy = '';
  //   }
  //   if (query.sortDesc === null || query.sortDesc === undefined) {
  //     query.sortDesc = false;
  //   }

  //   return apiClient.get(companyGroupApi, {
  //     params: {
  //       companyGroupId: query.companyGroupId,
  //       companyId: query.companyId,
  //       page: query.page,
  //       pageSize: query.pageSize,
  //       sortBy: query.sortBy,
  //       IsSortAscending: !query.sortDesc,
  //     },
  //     paramsSerializer(params) {
  //       return qs.stringify(params);
  //     },
  //   });
  // },

  // // Profile Overview
  // getProfileOverview(query) {
  //   return apiClient.get(profileOverviewApi, {
  //     params: {
  //       TargetType: query.TargetType,
  //       TargetId: query.TargetId,
  //       Date: query.Date,
  //       ProfileType: query.ProfileType,
  //       Distance: query.Distance,
  //       CompanyId: query.CompanyId,
  //     },
  //   });
  // },

  // * Profile get idling panel
  async getProfileIdling(axios, query) {
    const result = await axios.$get(profileIdlingApi, { params: query });
    return result;
  },

  // Profile Warning Count
  async getProfileWarningCount(axios, query) {
    const result = await axios.$get(profileWarningCountApi, { params: query });
    return result;
  },
  // getProfileWarningCount(query) {
  //   return apiClient.get(profileWarningCountApi, {
  //     params: {
  //       TargetType: query.TargetType,
  //       TargetId: query.TargetId,
  //       Date: query.Date,
  //       ProfileType: query.ProfileType,
  //       OutputType: query.OutputType,
  //       Distance: query.Distance,
  //       Page: query.Page,
  //       PageSize: query.PageSize,
  //       CompanyId: query.CompanyId,
  //     },
  //   });
  // },

  // Profile warning count per 100km
  async getProfileWarningCountper100km(axios, query) {
    const result = await axios.$get(profileWarningCountper100kmApi, { params: query });
    return result;
  },
  // getProfileWarningCountper100km(query) {
  //   return apiClient.get(profileWarningCountper100kmApi, {
  //     params: {
  //       TargetType: query.TargetType,
  //       TargetId: query.TargetId,
  //       OutputType: query.OutputType,
  //       Date: query.Date,
  //       ProfileType: query.ProfileType,
  //       Distance: query.Distance,
  //       Page: query.Page,
  //       PageSize: query.PageSize,
  //       CompanyId: query.CompanyId,
  //     },
  //   });
  // },

  // Profile Score
  async getProfileScore(axios, query) {
    const result = await axios.$get(profileScore, { params: query });
    return result;
  },
  // getProfileScore(query) {
  //   return apiClient.get(profileScore, {
  //     params: {
  //       TargetType: query.TargetType,
  //       TargetId: query.TargetId,
  //       OutputType: query.OutputType,
  //       Date: query.Date,
  //       ProfileType: query.ProfileType,
  //       Distance: query.Distance,
  //       Page: query.Page,
  //       PageSize: query.PageSize,
  //       CompanyId: query.CompanyId,
  //     },
  //   });
  // },

  // Profile Ranking
  async getProfileRanking(axios, query) {
    const result = await axios.$get(profileRank, { params: query });
    return result;
  },
  // getProfileRanking(query) {
  //   return apiClient.get(profileRank, {
  //     params: {
  //       TargetType: query.TargetType,
  //       TargetId: query.TargetId,
  //       OutputType: query.OutputType,
  //       Date: query.Date,
  //       ProfileType: query.ProfileType,
  //       Distance: query.Distance,
  //       Page: query.Page,
  //       PageSize: query.PageSize,
  //       CompanyId: query.CompanyId,
  //     },
  //   });
  // },

  // Profile warning group
  async getProfileWarningGroup(axios, query) {
    const result = await axios.$get(profileWarningGroup, { params: query });
    return result;
  },
  // getProfileWarningGroup(query) {
  //   return apiClient.get(profileWarningGroup, {
  //     params: {
  //       CompanyId: query.CompanyId,
  //       GroupName: query.GroupName,
  //       WarningType: query.WarningType,
  //     },
  //   });
  // },

  // Profile warning list
  async getProfileWarningList(axios, query) {
    const result = await axios.$get(profileWarningList, { params: query });
    return result;
  },
  // getProfileWarningList(query) {
  //   return apiClient.get(profileWarningList, {
  //     params: {
  //       TargetType: query.TargetType,
  //       TargetId: query.TargetId,
  //       Date: query.Date,
  //       ProfileType: query.ProfileType,
  //       WarningType: query.WarningType,
  //       IsDanger: query.IsDanger,
  //       Page: query.Page,
  //       PageSize: query.PageSize,
  //       CompanyId: query.CompanyId,
  //     },
  //   });
  // },
};
