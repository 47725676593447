import Vue from 'vue';
import moment from 'moment';

import { globalSettings } from '@/plugins/common';

const mixin = {
  data() {
    return {
      isMyMobile: false,
    };
  },
  methods: {
    onResize() {
      if (window.innerWidth < 600) this.isMyMobile = true;
      else this.isMyMobile = false;
    },
    log(m) {
      if (this.$config.logLevel === 'Trace') {
        // const stack = new Error().stack;
        // const caller = stack?.split('\n')[2].trim().split(' ')[1];
        let i;
        let o;
        let r = 1;
        for (i = arguments.length, o = new Array(i > 1 ? i - 1 : 0); r < i; r++)
          o[r - 1] = arguments[r];
        console.log(`[${m}]`, ...o);
      }
    },
  },
};

const validation = {
  data() {
    return {
      myrules: {
        required: (value) => {
          if (Array.isArray(value) && value.length === 0) {
            return this.$t('Required');
          }
          return !!value || this.$t('Required');
        },
      },
    };
  },
};

const util = {
  methods: {
    IsSPW(warningType) {
      return warningType?.includes('SPW');
    },
    getSpeedLimit(item) {
      return this.IsSPW(item.warningType) ? item.speedLimit || '' : '';
    },
    aggregateWarningFilterFunc(w, showDetail) {
      const warning = w.toLowerCase();
      if (
        !warning.includes('hmw') &&
        !warning.includes('pcw') &&
        !warning.includes('ldw') &&
        !warning.includes('spw') &&
        !warning.includes('ufcw')
      )
        return true;
      const isAgg = !(
        warning.includes('_') ||
        warning === 'lldw' ||
        warning === 'rldw'
      );
      return showDetail ? !isAgg : isAgg;
    },
    checkIsHQAdmin() {
      const val = this.$store.state.app.userRole;
      return val === 'HQ';
    },
  },
};

const warningsRelated = {
  methods: {
    warningDatePickerDisableDate(date) {
      return (
        date > new Date() ||
        date < new Date(new Date().setFullYear(new Date().getFullYear() - 2))
      );
    },
    olderThanNDays(dateToCheck, days) {
      const nDaysAgo = moment().subtract(days, 'days');
      const toCheck = moment(`${dateToCheck} +0800`, 'YYYY-MM-DD HH:mm:ss Z');
      return toCheck.isBefore(nDaysAgo);
    },
  },
};

const momentjs = {
  methods: {
    momentformatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
const common = {
  data() {
    return {
      globalSettings,
    };
  },
};

if (!Vue.__global_util__) {
  Vue.__global_util__ = true;
  Vue.mixin({
    mixins: [mixin, validation, util, momentjs, warningsRelated, common],
  });
}
/* eslint-disable */
import SettingApiService from '@/services/settings-service';
export default function ({ app, store }, inject) {
  const remoteLocales = async (axios,companyCode ) => {
    const zh_locale = "zh-Hant";
    const company = companyCode || localStorage.getItem('company')
    // subsequent call from AppBar.sendCompany , axios has header company already, so not adding
    // otherwise if added it will become kwah, kwah instead of kwah
    const warningLocaleResp = companyCode ? await SettingApiService.getWarningTypeSettings(
      axios, { headers: {Locale: zh_locale} }) : await SettingApiService.getWarningTypeSettings(
      axios,
      { headers: { company: company, Locale: zh_locale } }
    );

    if (warningLocaleResp) {
      store.state.app.warningsMetaInfo = warningLocaleResp;
      if (warningLocaleResp.items) {
        const warningLocale = warningLocaleResp.items.reduce((prev, cur) => {
          prev[cur.warningType] = cur.warningTypeText;
          return prev;
        }, {});

        app.i18n.mergeLocaleMessage('zh-Hant', warningLocale);
        console.log(`merge ${company} warnings locale`);
      }
    }
  };
  inject('remoteLocales', remoteLocales);
}
